import { useEffect } from 'react';

const Hannoverklebt = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 548);
    localStorage.setItem('club_name', 'Hannover klebt');

    window.location.assign('/');
  });

  return null;
};
export default Hannoverklebt;
